html,
body,
#app,
#app>div {
    height: 100%;
}

div#app {
    height: 100%;
}

h2.ui.header {
    color: #f5f5f5;
}

.showcase-container {
    padding: 0 10%;
}

.ui.grid.showcase-grid {
    padding: 0 40px;
}

.showcase-heading {
    font-size: 3.4rem;
    color: #f5f5f5;
    text-shadow: 1px 2px 7px #212121b3;
    letter-spacing: 4px;
}

.single-showcase-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

img.ui.fluid.image.main-showcase-img {
    border-radius: 4px;
    box-shadow: 0px 0px 20px #21212170;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition: transform 0.5s;
    margin-bottom: 20px;
}

img.ui.fluid.image.main-showcase-img:hover {
    cursor: pointer;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition: transform 0.5s;
}

.showcase-value {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    letter-spacing: 3px;
    font-weight: 100;
}

.showcase-type {
    text-align: center;
    line-height: 2.2rem;
    font-size: 0.9rem;
    margin-top: 5px;
    letter-spacing: 2px;
    color: #212121;
    padding: 0px 20px;
    background: #fff;
    font-weight: 400;
}

.showcase-details {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 35px;
    color: #212121;
    font-weight: 300;
    text-align: center;
}

a.showcase-link {
    font-size: 2rem;
    color: #f5f5f5;
    text-shadow: 1px 2px 7px #212121b3;
    letter-spacing: 4px;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    transition: transform 0.5s;
}

a.showcase-link:hover {
    font-size: 2.5rem;
    transition: transform 0.5s;
    transform: scale(1.5);
}

a.showcase-link-dark {
    font-size: 2rem;
    color: #333333;
    letter-spacing: 4px;
    /* transition: 0.2s; */
}

a.showcase-link-dark:hover {
    font-size: 2.5rem;
    /* transition: 0.2s; */
    transform: scale(1.1);
}

.visit-link {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
}

/* New Styles */

.new-nav {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    z-index: 9500;
    top: 0px;
    transition: 0.2s ease;
    padding-top: 30px;
    padding-bottom: 30px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
}

.new-nav-mobile {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    padding-left: 5%;
    padding-right: 5%;
    justify-content: space-between;
    position: fixed;
    left: 0;
    right: 0;
    background-color: white;
    font-family: 'Lato', sans-serif;
    font-size: 1rem;
    z-index: 9500;
    top: 0px;
    transition: 0.2s ease;
    padding-top: 30px;
    padding-bottom: 15px;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px;
    flex-flow: row wrap;
}

.nav-links.mobile-nav {
    flex-flow: column;
    align-items: flex-start;
    width: 98%;
}

.nav2 {
    padding-top: 20px;
    padding-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px;
    transition: 0.2s ease;
}

.home-top-container {
    min-height: 100vh;
    padding-top: 100px;
}

.brand {
    color: #414141;
    font-weight: 400;
    font-size: 1.2rem;
    text-transform: uppercase;
}

.nav-links {
    display: flex;
    width: 25%;
    justify-content: space-around;
}

a.new-nav-item {
    color: #414141;
    font-size: 1.2rem;
    transition: all 0.2s ease;
}

a.new-nav-item:hover {
    opacity: 0.5;
    transition: all 0.2s ease;
}

a.new-nav-item-mobile {
    color: #414141;
    font-size: 1.2rem;
    transition: all 0.2s ease;
    margin: 10px 0;
}

a.new-nav-item-mobile:hover {
    opacity: 0.5;
    transition: all 0.2s ease;
}

.home-top-container {
    min-height: 95vh;
    padding-top: 80px;
    display: flex;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
}

.showcase-top-container {
    min-height: 60vh;
    padding-top: 80px;
    display: flex;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;
}

div#showcase-main-content {
    padding-top: 300px;
    padding-bottom: 100px;
    clip-path: polygon(0 25%, 100% 0%, 100% 100%, 0% 100%);
    background: linear-gradient(to bottom, #74ebd5, #acb6e5);
    background: #74ebd5;
}

.showcase-main-img {
    height: 400px;
}

.showcase-main-img img {
    height: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 20px #21212170;
}

.showcase-title {
    font-size: 3.5rem;
    color: #414141;
    font-weight: 800;
    margin-top: 100px;
}

.showcase-main-img {
    height: 400px;
    position: absolute;
    z-index: 999;
    top: calc(100vh - 450px);
}

.scroll-container {
    height: 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

i.huge.icon,
i.huge.icons {
    line-height: 1;
    vertical-align: middle;
    font-size: 3.7em;
    opacity: 0.5;
    color: #212121;
    transition: all 0.2s ease;
}

i.huge.icon:hover,
i.huge.icons:hover {
    opacity: 1;
    transition: all 0.2s ease;
    transform: scale(1.5);
}

.top-content {
    width: 50%;
}

img.self-image {
    height: 75vh;
}

.top-title {
    font-family: 'Montserrat', 'Avenir', sans-serif;
    font-size: 48px;
    color: #414141;
    margin-bottom: 50px;
}

.top-desc {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    line-height: 40px;
    font-size: 20px;
    margin-bottom: 50px;
    color: #414141;
}

a.inline-link {
    border-bottom: 1px solid grey;
    transition: opacity 0.2s ease;
    color: #414141;
}

a.inline-link:hover {
    opacity: 0.6;
    transition: opacity 0.2s ease;
    color: #414141;
}

div#main-content {
    background-color: #FAFAFA;
    padding-top: 5%;
    padding-bottom: 5%;
    padding-left: calc(100vw - 85%);
    padding-right: calc(100vw - 85%);
    display: flex;
    justify-content: center;
    flex-flow: column;
}
.experience {
    padding-bottom: 5%;
    padding-left: calc(100vw - 90%);
    padding-right: calc(100vw - 90%);
    display: flex;
    justify-content: center;
    flex-flow: column;
}

.exp-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #d7d7d7;
    padding: 25px 5%;
    color: #212121;
}

.exp-row:hover {
    background: #f5f5f5;
    color: initial;
}

.exp-position {
    font-size: 2rem;
    color: #74ebd5;
    margin-bottom: 5px;
    line-height: initial;
}

.exp-place, .exp-date {
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 100;
    letter-spacing: 1px;
    margin-top: 10px;
}

.project-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
}

.main-page-meta-data {
    text-align: center;
    font-size: 1rem;
    color: #b8b8b8;
    font-weight: 100;
    font-family: 'Montserrat', 'Avenir', sans-serif;
    padding: 0 25%;
    margin-bottom: 5%;
}

.parent-container {
    margin: auto;
    width: 400px;
    height: 350px;
    background-color: white;
    box-shadow: 0px 8px 35px -7px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    margin-right: 50px;
    margin-bottom: 100px;
    transition: 0.2s ease;
}

.parent-container:hover {
    transition: 0.2s ease;
    transform: scale(1.05);
}

.parent-container:hover .child-container img {
    transform: translateY(-3px) translateX(45px);
    transition: 0.2s ease;
}

.parent-container:hover .child-container img#destinder-logo {
    transform: rotate(-15deg) scale(1.05) translateX(25px);
    transition: 0.2s ease;
}

.parent-container:hover .child-container img#plane {
    transform: translateY(-75px) translateX(-87px) rotate(275deg);
    transition: 0.2s ease;
}

img#hex {
    height: 228px;
}

#canvas-child {
    width: 540px;
    margin-left: 13px;
    padding-top: 50px;
}

.parent-container:hover .child-container img#gilagoat-logo {
    transform: rotate(15deg) scale(1.05) translateX(25px) translateY(-30px);
    transition: 0.2s ease;
}

.parent-container:hover .child-container img#canvas-logo {
    animation-name: spin;
    animation-duration: 10000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    /* transition: 0.2s ease; */
}

img#destinder-logo {
    transition: 0.2s ease;
}

img#gilagoat-logo {
    transition: 0.2s ease;
}

.project-title {
    font-family: 'Montserrat', 'Avenir', sans-serif;
    font-size: 2rem;
    color: #414141;
    letter-spacing: 1px;
    margin-bottom: 35px;
    line-height: 2rem;
}

.project-details {
    width: 50%;
}

.project-title {
    font-family: 'Montserrat', 'Avenir', sans-serif;
    font-size: 2rem;
    color: #414141;
    letter-spacing: 1px;
    margin-bottom: 35px;
}

.project-desc {
    font-family: "Lato", sans-serif;
    line-height: 30px;
    font-size: 16px;
    margin-bottom: 40px;
}

.project-show {
    margin-right: 50px;
}

.footer {
    height: 250px;
    background-color: #E6E7E9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5%;
}

.footer-left {
    width: 45%;
}

.typed-header {
    font-size: 1.5rem;
    line-height: 2.5rem;
    opacity: 0.5;
}

.copyright {
    font-size: 1.4rem;
    margin-bottom: 10px;
    color: #414141;
    opacity: 0.5;
}

i.large.icon,
i.large.icons {
    font-size: 2.5rem;
}

.parent-container#destiny-players {
    /* background: linear-gradient(135deg, #ECF0F1, #B1B4B5); */
    background: #191a1d;
}


.parent-container#destinder {
    /* background: linear-gradient(135deg, #ECF0F1, #B1B4B5); */
    background: #303E4E;
}

.parent-container#sellout {
    /* background: linear-gradient(135deg, #ECF0F1, #D4B7A6); */
    /* background: #D4B7A6; */
    background: #D75745;
}

.parent-container#europe {
    /* background: linear-gradient(135deg, #ECF0F1, #D4B7A6); */
    /* background: #D4B7A6; */
    /* background: #F8C6D8; */
    background: linear-gradient(115.62291889406674deg, #f8c6d7 5.272159428911362%, #afe9f9 97.18173706127305%);
}

.parent-container#humanize {
    background: linear-gradient(115.62291889406674deg, #8e2de2 5.272159428911362%, #4a00e0 97.18173706127305%);
}


.parent-container#gilagoat {
    /* background: linear-gradient(135deg, #ECF0F1, #E6863C); */
    background: #E6863C;
}

.parent-container#icpm {
    /* background: linear-gradient(135deg, #f96,#ff5e62); */
    background: #59C58E;
}

.parent-container#canvas {
    /* background: linear-gradient(135deg, #f96,#ff5e62); */
    background: #646464;
}

.child-container {
    /* margin-top: 50px; */
    padding-top: 93px;
    overflow: hidden;
    width: 400px;
    height: calc(304px);
    transform: rotate(0deg) scale(1.3);
}

#destiny-players img {
    width: 265px;
    margin-bottom: -168px;
    margin-left: 27px;
}


#icpm img {
    width: 265px;
    margin-top: -90px;
    transition: 0.2s ease;
}

#gilagoat img {
    margin-left: 110px;
    transition: 0.2s ease;
    width: 60%;
}

#sellout img {
    transition: 0.2s ease;
    width: 77%;
}

#europe img {
    transition: 0.2s ease;
    margin-left: 100px;
    position: relative;
    width: 77%;
    bottom: 37px;
    transform: rotate(260deg);
    opacity: 0.75;
}

#humamize img {
    transition: 0.2s ease;
    margin-left: 100px;
    position: relative;
    width: 77%;
    bottom: 37px;
    transform: rotate(260deg);
    opacity: 0.75;
}

#destinder .child-container {
    padding-top: 55px;
}

#gilagoat .child-container {
    padding-top: 55px;
}

#canvas img {
    transition: 0.2s ease;
    width: 57%;
}

.project-dates {
    opacity: 0.8;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
}

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.showcase-section-title {
    font-size: 3rem;
    color: #333333;
    font-weight: 800;
    margin-bottom: 30px;
    line-height: 3.5rem;
    text-align: center;
}

.details-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.showcase-group {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.showcase-background {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
    line-height: 2rem;
    /* padding-top: 75px;765 */
}

.showcase-stack {
    margin: 50px auto;
    /* background: #f7f7f7; */
    padding: 20px 30px;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    flex-flow: column;
    align-items: center;
    /* border-radius: 5px; */
    /* -webkit-box-shadow: 0px 3px 10px 1px #21212170; */
    /* box-shadow: 0px 3px 10px 1px #21212170; */
}

.tech-stack {
    width: 100%;
    display: flex;
    flex-flow: row;
    justify-content: space-evenly;
    align-items: center;
}

.tech-headers,
.tech-values {
    /* height: 100%; */
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}

.tech-header {
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 3rem;
    letter-spacing: 0.05rem;
    text-align: right;
}

.tech-value {
    font-weight: 100;
    font-size: 1.2rem;
    line-height: 3rem;
    letter-spacing: 0.05rem;
}

.showcase-second {
    width: 90%;
    margin: 0 auto;
}

img.erd {
    width: 100%;
}

img.erd#sellout-erd {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    display: flex
}

i#scroll-icon:hover {
    cursor: pointer;
}

.authentication {
    display: flex;
    width: 95%;
    margin: 30px auto;
    justify-content: space-between;
}

.showcase-split-title {
    font-size: 3rem;
    color: #333333;
    font-weight: 800;
    line-height: 3.5rem;
}

.showcase-split-text {
    width: 60%;
    font-size: 1.3rem;
    line-height: 2.5rem;
    letter-spacing: 1px;
    margin-bottom: 30px;
}

code {
    background: #e8e6e6;
    padding: 5px 7px;
    border-radius: 4px;
}

.showcase-third {
    padding: 5%;
    background: #f5f5f5;
    margin: 50px auto;
    margin-bottom: 0;
}

.showcase-single-img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15%;
}

.showcase-double-img {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.feature-left {
    display: flex;
    width: 95%;
    margin: 30px auto;
    justify-content: space-between;
}

.feature-right {
    display: flex;
    width: 95%;
    margin: 30px auto;
    justify-content: space-between;
}

.showcase-fourth {
    padding: 5%;
    background: #fff;
}

.showcase-fifth {
    padding: 5%;
    background: #f5f5f5;
    margin: 50px auto;
    margin-bottom: 0;
}

.showcase-sixth {
    padding: 5%;
    background: #fff;
}

.double-img {
    width: 45%;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px #21212170;
}

.double-img-show {
    width: 50%;
    margin: 10px;
}

.single-img {
    width: 100%;
    border-radius: 15px;
    box-shadow: 0px 0px 20px #21212170;
}

.single-img-show {
    width: 100%;
}

.divider {
    margin: 1rem 0;
    line-height: 1;
    height: 0;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: rgba(0, 0, 0, .85);
    user-select: none;
    font-size: 1rem;
    border-color: transparent !important;
}

.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgba(34, 36, 38, .15);
    border-bottom: 1px solid rgba(255, 255, 255, .1);
}

.main-page-title {
    text-align: center;
    font-family: 'Montserrat', 'Avenir', sans-serif;
    font-size: 3rem;
    color: #414141;
    letter-spacing: 1px;
    margin-bottom: 5%;
    line-height: initial;
}

    .top-image {
        width: 40%;
    }

/* Mobile branding  */

@media only screen and (max-width: 767px) and (min-width: 320px) {

    .main-page-meta-data {
        padding: 0 5%;
    }


    /* new styles */
    .home-top-container {
        min-height: 100vh;
        padding-top: 100px;
        display: flex;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        align-items: center;
        flex-flow: column;
    }
    .top-content {
        width: 100%;
    }
    .top-image {

        width: 100%;
    }
    img.self-image {
        height: auto;
        width: 100%;
    }
    .nav-links {
        display: flex;
        width: 55%;
        justify-content: space-around;
    }
    .scroll-container {
        display: none;
    }
    div#main-content {
        padding: 20% 10%;
    }
    .parent-container {
        margin: auto;
        width: 350px;
        height: 300px;
        margin-bottom: 25px;
    }
    .project-container {
        align-items: center;
        flex-flow: row wrap;
        justify-content: center;
        margin-bottom: 50px;
    }
    .project-details {
        width: 100%;
        text-align: center;
    }
    .project-title {
        margin-bottom: 15px;
        line-height: 2rem;
    }
    .project-show {
        margin-right: 0 !important;
    }
    #icpm .child-container {
        margin-left: 55px !important;
    }
    #destinder .child-container {
        padding-left: 15px;
    }
    #gilagoat .child-container {
        padding-left: 15px;
    }
    .child-container {
        /* margin-top: 50px; */
        padding-top: 93px;
        overflow: hidden;
        width: 400px;
        height: calc(261px);
        -webkit-transform: rotate(0deg) scale(1.3);
        -ms-transform: rotate(0deg) scale(1.3);
        transform: rotate(0deg) scale(1.3);
    }
    #icpm img {
        width: 225px;
        margin-top: -90px;
        -webkit-transition: 0.2s ease;
        -o-transition: 0.2s ease;
        transition: 0.2s ease;
    }
    #sellout img {
        width: 70%;
    }
    #sellout .child-container {
        padding-left: 30px;
        padding-top: 65px;
    }
    .showcase-main-img {
        height: 200px;
        top: calc(100vh - 400px);
    }
    .showcase-title {
        line-height: 4rem;
    }
    .typed-header {
        display: none;
    }
    #canvas-child {
        width: 425px;
        margin-left: 40px;
        padding-top: 50px;
    }
    /* end new styles */
    .showcase-details {
        width: 90%;
    }
    .showcase-container {
        padding: 0;
    }
    div#showcase-main-content {
        padding-top: 240px;
        clip-path: polygon(0 10%, 100% 0%, 100% 100%, 0% 100%);
    }
    .showcase-background {
        width: 100%;
        padding-top: 50px;
        text-align: justify;
    }
    .showcase-stack {
        width: 100%;
        padding: 0;
    }
    .authentication {
        flex-flow: column;
    }
    .showcase-split-title {
        margin-bottom: 20px;
    }
    .showcase-split-text {
        width: 95%;
        text-align: justify;
    }
    .showcase-double-img {
        flex-flow: column;
    }
    .double-img {
        width: 100%;
    }
    .double-img-show {
        width: 100%;
    }
    .single-img {
        width: 100%;
    }
    .single-img-show {
        width: 100%;
    }
    .feature-left {
        flex-flow: column-reverse;
    }
    .showcase-single-img {
        padding: 0;
    }
    .feature-right {
        flex-flow: column;
    }
    .visit-link {
        margin-bottom: 50px;
    }
    img.erd#sellout-erd {
        width: 100%;
    }
}

/* Tablet */

@media only screen and (min-width: 768px) and (max-width: 1100px) {}

/* Large Screens */

@media only screen and (min-width: 1500px) {
    div#showcase-main-content {
        padding-top: 400px;
    }
    .showcase-main-img {
        height: 500px;
        position: absolute;
        z-index: 999;
        top: calc(100vh - 550px);
    }
}
